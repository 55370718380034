import styles from '@/styles/Main/MainContainer.module.scss';
import { MouseEventHandler, ReactElement } from 'react';
import { IconProps } from '../Fields/BtSelectField';
export interface buttonProps {
  icon?: ReactElement<IconProps>;
  label: string;
  onClick: MouseEventHandler;
  width?: string;
  position: string;
}
const PassiveButton = ({
  width = 'inheri',
  icon = <></>,
  label,
  position = 'fx',
  onClick,
}: buttonProps) => {
  return (
    <button
      className={styles.terminalpassiveButton}
      onClick={onClick}
      style={{
        width: width,
        position: position && position === 'abs' ? 'fixed' : 'inherit',
        margin: position && position === 'abs' ? '20px' : 's',
        bottom: '10px',
        left: 0,
      }}
    >
      {icon}
      {label}
    </button>
  );
};

export default PassiveButton;
