import styles from '@/styles/Main/Home/Home.module.scss';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import MainFooter from './MainFooter';
import NewLandingHeader from './NewLandingHeader';
interface LayoutProps {
  children: ReactNode;
}

const UnAuthorizedLayout = ({ children }: LayoutProps) => {
  const router = useRouter();
  // const { data: userSession, status } = useSession();
  // const logoutFunction = async () => {
  //   await signOut({ redirect: false });

  //   await api.get('/auth/logout', {
  //     headers: { token: userSession?.user.token },
  //   });
  //   router.push('/');
  // };
  // useEffect(() => {
  //   if (status === 'authenticated') {
  //     logoutFunction();
  //   }
  // }, [status]);
  return (
    <div
      className={styles.mainHomecontainer}
      style={{
        background: router.pathname === '/' ? '#2a2a2e' : '',
        flex: 1,
      }}
    >
      <NewLandingHeader />
      <div>{children}</div>

      <MainFooter />
      {/* <SupportFAB /> */}
    </div>
  );
};

export default UnAuthorizedLayout;
