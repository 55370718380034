import PassiveButton from '@/Components/Buttons/PassiveButton';
import TerminalButton from '@/Components/Buttons/TerminalButton';
import BtSelectField from '@/Components/Fields/BtSelectField';
import TerminalSteppers from '@/Components/Layout/Steppers';
import DialogModals from '@/Components/Modals/DialogModals';
import DollarIconSvg from '@/Components/SVG/DollarIconSvg';
import ErrorIconSvg from '@/Components/SVG/ErrorIconSvg';
import LocationIconSvg from '@/Components/SVG/LocationIconSvg2';
import MessageIconSvg from '@/Components/SVG/MessageIconSvg';
import {
  useCompanyDetailsStore,
  useUserDetailsStore,
} from '@/Stores/zusStores';
import api from '@/services/api';
import { fetcher } from '@/services/fetcher';
import styles from '@/styles/page/LoginRegister/loginRegisterMain.module.scss';
import { useLoading } from '@/utils/LoadingContext';
import { ModalContext } from '@/utils/ModalContext';
import { Close } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Popper,
} from '@mui/material';
import { useRouter } from 'next/router';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { components } from 'react-select';
import ReactCodeInput from 'react-verification-code-input';
import useSWR from 'swr';
interface ControlProps {
  children: React.ReactNode;
  selectProps: {
    selected: any;
  };
}

const languageOptions = [
  { value: 'Afrikaans', label: 'Afrikaans' },
  { value: 'Albanian', label: 'Albanian' },
  { value: 'Amharic', label: 'Amharic' },
  { value: 'Arabic', label: 'Arabic' },
  { value: 'Armenian', label: 'Armenian' },
  { value: 'Azerbaijani', label: 'Azerbaijani' },
  { value: 'Basque', label: 'Basque' },
  { value: 'Belarusian', label: 'Belarusian' },
  { value: 'Bengali', label: 'Bengali' },
  { value: 'Bosnian', label: 'Bosnian' },
  { value: 'Bulgarian', label: 'Bulgarian' },
  { value: 'Catalan', label: 'Catalan' },
  { value: 'Cebuano', label: 'Cebuano' },
  { value: 'Chichewa', label: 'Chichewa' },
  { value: 'Chinese (Simplified)', label: 'Chinese (Simplified)' },
  { value: 'Chinese (Traditional)', label: 'Chinese (Traditional)' },
  { value: 'Croatian', label: 'Croatian' },
  { value: 'Czech', label: 'Czech' },
  { value: 'Danish', label: 'Danish' },
  { value: 'Dutch', label: 'Dutch' },
  { value: 'English', label: 'English' },
  { value: 'Esperanto', label: 'Esperanto' },
  { value: 'Estonian', label: 'Estonian' },
  { value: 'Filipino', label: 'Filipino' },
  { value: 'Finnish', label: 'Finnish' },
  { value: 'French', label: 'French' },
  { value: 'Frisian', label: 'Frisian' },
  { value: 'Galician', label: 'Galician' },
  { value: 'Georgian', label: 'Georgian' },
  { value: 'German', label: 'German' },
  { value: 'Greek', label: 'Greek' },
  { value: 'Gujarati', label: 'Gujarati' },
  { value: 'Haitian Creole', label: 'Haitian Creole' },
  { value: 'Hausa', label: 'Hausa' },
  { value: 'Hawaiian', label: 'Hawaiian' },
  { value: 'Hebrew', label: 'Hebrew' },
  { value: 'Hindi', label: 'Hindi' },
  { value: 'Hmong', label: 'Hmong' },
  { value: 'Hungarian', label: 'Hungarian' },
  { value: 'Icelandic', label: 'Icelandic' },
  { value: 'Igbo', label: 'Igbo' },
  { value: 'Indonesian', label: 'Indonesian' },
  { value: 'Irish', label: 'Irish' },
  { value: 'Italian', label: 'Italian' },
  { value: 'Japanese', label: 'Japanese' },
  { value: 'Javanese', label: 'Javanese' },
  { value: 'Kannada', label: 'Kannada' },
  { value: 'Kazakh', label: 'Kazakh' },
  { value: 'Khmer', label: 'Khmer' },
  { value: 'Kinyarwanda', label: 'Kinyarwanda' },
  { value: 'Korean', label: 'Korean' },
  { value: 'Kurdish (Kurmanji)', label: 'Kurdish (Kurmanji)' },
  { value: 'Kyrgyz', label: 'Kyrgyz' },
  { value: 'Lao', label: 'Lao' },
  { value: 'Latin', label: 'Latin' },
  { value: 'Latvian', label: 'Latvian' },
  { value: 'Lithuanian', label: 'Lithuanian' },
  { value: 'Luxembourgish', label: 'Luxembourgish' },
  { value: 'Macedonian', label: 'Macedonian' },
  { value: 'Malagasy', label: 'Malagasy' },
  { value: 'Malay', label: 'Malay' },
  { value: 'Malayalam', label: 'Malayalam' },
  { value: 'Maltese', label: 'Maltese' },
  { value: 'Maori', label: 'Maori' },
  { value: 'Marathi', label: 'Marathi' },
  { value: 'Mongolian', label: 'Mongolian' },
  { value: 'Myanmar (Burmese)', label: 'Myanmar (Burmese)' },
  { value: 'Nepali', label: 'Nepali' },
  { value: 'Norwegian', label: 'Norwegian' },
  { value: 'Odia (Oriya)', label: 'Odia (Oriya)' },
  { value: 'Pashto', label: 'Pashto' },
  { value: 'Persian', label: 'Persian' },
  { value: 'Polish', label: 'Polish' },
  { value: 'Portuguese', label: 'Portuguese' },
  { value: 'Punjabi', label: 'Punjabi' },
  { value: 'Romanian', label: 'Romanian' },
  { value: 'Russian', label: 'Russian' },
  { value: 'Samoan', label: 'Samoan' },
  { value: 'Scots Gaelic', label: 'Scots Gaelic' },
  { value: 'Serbian', label: 'Serbian' },
  { value: 'Sesotho', label: 'Sesotho' },
  { value: 'Shona', label: 'Shona' },
  { value: 'Sindhi', label: 'Sindhi' },
  { value: 'Sinhala', label: 'Sinhala' },
  { value: 'Slovak', label: 'Slovak' },
  { value: 'Slovenian', label: 'Slovenian' },
  { value: 'Somali', label: 'Somali' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'Sundanese', label: 'Sundanese' },
  { value: 'Swahili', label: 'Swahili' },
  { value: 'Swedish', label: 'Swedish' },
  { value: 'Tajik', label: 'Tajik' },
  { value: 'Tamil', label: 'Tamil' },
  { value: 'Tatar', label: 'Tatar' },
  { value: 'Telugu', label: 'Telugu' },
  { value: 'Thai', label: 'Thai' },
  { value: 'Turkish', label: 'Turkish' },
  { value: 'Turkmen', label: 'Turkmen' },
  { value: 'Ukrainian', label: 'Ukrainian' },
  { value: 'Urdu', label: 'Urdu' },
  { value: 'Uyghur', label: 'Uyghur' },
  { value: 'Uzbek', label: 'Uzbek' },
  { value: 'Vietnamese', label: 'Vietnamese' },
  { value: 'Welsh', label: 'Welsh' },
  { value: 'Xhosa', label: 'Xhosa' },
  { value: 'Yiddish', label: 'Yiddish' },
  { value: 'Yoruba', label: 'Yoruba' },
  { value: 'Zulu', label: 'Zulu' },
];

interface CompanyDetails {
  accountType: string;
  name: string;
  tradeLicenseNumber: string;
  email: string;
  crmName: string;
  crmUrl: string;
  phoneNumber: string;
}
interface UserDetails {
  firstName: string;
  lastName: string;
  email: string;
  brn?: number;
  phoneNumber: string;
}
interface LocationOption {
  value: string;
  label: string;
}
interface PostDetailsState {
  location: LocationOption[];
  whatsappNotify: boolean;
  emailNotify: boolean;
  newProperty: boolean;
  newRequirements: boolean;
  dailyTransaction: { personal: boolean; dld: boolean };
  weeklyReport: { personal: boolean; dld: boolean };
  monthlyReport: { personal: boolean; dld: boolean };
  user: string;
}
interface IndividualRegisterPropos {
  activeStep: number;
  setActiveStep: (nL: number) => void;
  accountType: string;
  setAccountType: (nL: string) => void;
}

const IndividualRegister = ({
  activeStep,
  setActiveStep,
  accountType,
  setAccountType,
}: IndividualRegisterPropos) => {
  const genericEmailDomains = [
    'gmail.com',
    'yahoo.com',
    'outlook.com',
    'aol.com',
    'mail.com',
    'protonmail.com',
    'zoho.com',
    'gmx.com',
    'yandex.com',
    'tutanota.com',
    'mail.ru',
    'icloud.com',
    'inbox.com',
    'fastmail.com',
    'hushmail.com',
    'startmail.com',
    'hotmail.com',
    'gmail',
    'yahoo',
    'outlook',
    'aol',
    'mail',
    'protonmail',
    'zoho',
    'gmx',
    'yandex',
    'tutanota',
    'mail.ru',
    'icloud',
    'inbox',
    'fastmail',
    'hushmail',
    'startmail',
    'hotmail',
  ];
  const { data: validatedEmails, error: mevalids } = useSWR(
    '/auth/validated-emails',
    fetcher,
    {
      revalidateOnMount: true,
    }
  );
  const [formError, setformError] = useState({
    accountType: false,
    firstName: false,
    lastName: false,
    email: false,
    brn: false,
    phoneNumber: false,
    reraCard: false,
    budgetExpertise: false,
    profilePic: false,
    budget: false,
    location: false,
    code: false,
    languages: false,
    password: false,
    confirmPassword: false,
  });
  const [companyFormError, setcompanyFormError] = useState({
    accountType: false,
    name: false,
    tradeLicenseNumber: false,
    email: false,
    crmName: false,
    crmUrl: false,
    phoneNumber: false,
    website: false,
    description: false,
  });
  const [displayNewPassword, setdisplayNewPassword] = useState(false);
  const { userDetails, setUserDetails } = useUserDetailsStore();
  const { companyDetails, setCompanyDetails } = useCompanyDetailsStore();
  const router = useRouter();
  useEffect(() => {
    if (accountType !== 'Individual') {
      setCompanyDetails({ ...companyDetails, accountType: accountType });
    }
  }, [accountType]);

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };
  const handleInputCompanyChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setCompanyDetails({
      ...companyDetails,
      [name]: value,
    });
  };
  const { openModal } = useContext(ModalContext);
  const handleInputClear = (
    name: string,
    value: string | undefined | number
  ) => {
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };
  const handleInputClearCompany = (
    name: string,
    value: string | undefined | number
  ) => {
    setCompanyDetails({
      ...companyDetails,
      [name]: value,
    });
  };

  function validateUserDetails(): boolean {
    const { firstName, lastName, email, phoneNumber, languages } = userDetails;

    const errors = {
      firstName: firstName.trim() === '',
      lastName: lastName.trim() === '',
      languages: languages.length === 0,
      email:
        email.trim() === '' ||
        (!userDetails?.email.includes('@') &&
          genericEmailDomains.includes(
            String(userDetails?.email.split('@')[1].split('.')[0])
          ) &&
          !validatedEmails.includes(userDetails?.email.toLocaleLowerCase())),

      phoneNumber: !phoneNumber.startsWith('+971') || phoneNumber.length <= 8,
    };

    setformError((prevFormError) => ({
      ...prevFormError,
      ...errors,
    }));

    return Object.values(errors).every((error) => !error);
  }
  function validateUserPassword(): boolean {
    const { password, confirmPassword } = userDetails;

    const errors = {
      password: password.trim() === '' || password.length < 6,
      confirmPassword:
        confirmPassword.trim() === '' ||
        confirmPassword !== password ||
        confirmPassword.length < 6,
    };

    setformError((prevFormError) => ({
      ...prevFormError,
      ...errors,
    }));

    return Object.values(errors).every((error) => !error);
  }
  function validateUserDetailsSecond(): boolean {
    const {
      firstName,
      lastName,
      email,

      phoneNumber,
      budgetExpertise,
      budget,
      location,
    } = userDetails;

    const errors = {
      firstName: firstName.trim() === '',
      lastName: lastName.trim() === '',
      email: email.trim() === '',

      phoneNumber: !phoneNumber.startsWith('+971') || phoneNumber.length <= 4,
      profilePic: !selectedFile || selectedFile === undefined,
      reraCard: !selectedReraCard || selectedReraCard === undefined,
    };

    setformError((prevFormError) => ({
      ...prevFormError,
      ...errors,
    }));

    return Object.values(errors).every((error) => !error);
  }
  const budgetItems = [
    { value: 'Ultra', label: 'Ultra-Luxury Properties (50M+)' },
    { value: 'Elite', label: 'Elite Properties (20M-50M)' },
    { value: 'Premier', label: 'Premier Properties (10M-20M)' },
    { value: 'Luxury', label: 'Luxury Properties (5M-10M)' },
    { value: 'Affordable', label: 'Affordable Properties (500K-5M)' },
  ];
  const [verifyCode, setverifyCode] = useState(0);

  function validateCompanyDetails(): boolean {
    const { name, accountType, email, phoneNumber, website, description } =
      companyDetails;

    const errors = {
      name: name.trim() === '',
      email: email.trim() === '',
      phoneNumber: !phoneNumber.startsWith('+971') || phoneNumber.length <= 4,
    };

    setcompanyFormError((prevFormError) => ({
      ...prevFormError,
      ...errors,
    }));

    return Object.values(errors).every((error) => !error);
  }

  const { setLoadingState } = useLoading();
  const handleNext = async () => {
    setLoadingState(true);
    if (activeStep < 2 && validatedEmails) {
      const validate = validateUserDetails();
      if (validate) {
        if (
          userDetails?.email.includes('@') &&
          genericEmailDomains.includes(
            String(userDetails?.email.split('@')[1].split('.')[0])
          ) &&
          !validatedEmails.includes(userDetails?.email.toLocaleLowerCase())
        ) {
          setformError({ ...formError, email: true });
        } else {
          const getCode = await api.post(
            '/auth/verify-email',
            { email: userDetails?.email, firstName: userDetails?.firstName },
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            }
          );
          if (getCode.data.message === 'success') {
            console.log(getCode.data, 'verify');
            setverifyCode(getCode.data.verifyCode);
            //@ts-ignore
            setActiveStep((prev: number) => prev + 1);
          } else if (getCode.data.message === 'exists') {
            openModal(
              <DialogModals
                action={() => {
                  router.push(
                    '/login/reset-password?email=' + userDetails.email
                  );
                }}
                label="Go to Reset Password"
                title="User Exists"
                type="warning"
                message={`User with email <b>${userDetails?.email}</b> Exists. <br/>  Please Reset-password to login.`}
              />
            );
          } else if (getCode.data.message === 'pending') {
            openModal(
              <DialogModals
                action={() => {
                  router.push(
                    '/login/reset-password?email=' + userDetails.email
                  );
                }}
                label="Go to Reset Password"
                title="User Exists"
                type="warning"
                message={`User with email <b>${userDetails?.email}</b> Exists. <br/>  Please Reset-password to login.`}
              />
            );
          }
        }
        //@ts-ignore
      }
      setLoadingState(false);
    } else if (activeStep === 2) {
      if (displayNewPassword) {
        const validate = validateUserPassword();
        if (validate) {
          //@ts-ignore
          setActiveStep((prev: number) => prev + 1);
        }
      } else {
        setformError({ ...formError, code: true });
      }
    } else {
      const validate = validateUserDetailsSecond();
      if (validate) {
        //@ts-ignore
        setActiveStep((prev: number) => prev + 1);
      }
    }
    setLoadingState(false);
  };

  const handleBack = () => {
    if (activeStep > 1) {
      //@ts-ignore
      setActiveStep((prev: number) => prev - 1);
    }
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const { data, error: me } = useSWR(
    '/admin/unique-merged-locations',
    fetcher,
    {
      revalidateOnMount: true,
    }
  );
  const [anchorPhoto, setAnchorPhoto] = useState<null | HTMLElement>(null);

  const handleClickPhoto = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorPhoto(anchorPhoto ? null : event.currentTarget);
  };

  const handleSubmitCode = async (e: any) => {
    setLoadingState(true);
    if (Number(e) === verifyCode) {
      //@ts-ignore
      setdisplayNewPassword(true);
    } else {
      setformError({ ...formError, code: true });
    }
    setLoadingState(false);
    // console.log(e, "eee");
  };
  const [selectedFile, setSelectedFile] = useState();
  const changeHandler = (event: any) => {
    if (formError.profilePic) {
      setformError({ ...formError, profilePic: false });
    }
    setSelectedFile(event.target.files[0]);
  };
  const [selectedReraCard, setselectedReraCard] = useState<File | null>();
  const changeHandlerRera = (event: any) => {
    if (formError.reraCard) {
      setformError({ ...formError, reraCard: false });
    }
    setselectedReraCard(event.target.files[0]);
  };

  const [anchorRera, setAnchorRera] = useState<null | HTMLElement>(null);

  const handleClickRera = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorRera(anchorRera ? null : event.currentTarget);
  };
  const openRera = Boolean(anchorRera);
  const idRera = openRera ? 'simple-popper' : undefined;
  const [selectedBusinessCard, setselectedBusinessCard] = useState();
  const changeHandlerBusiness = (event: any) => {
    setselectedBusinessCard(event.target.files[0]);
  };

  const openPhoto = Boolean(anchorPhoto);
  const idPhoto = openPhoto ? 'simple-popper' : undefined;
  const handleChange = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    setUserDetails({
      ...userDetails,
      [name]: event.target.checked,
    });
  };

  // const data = projects;

  const [postDetails, setpostDetails] = useState<PostDetailsState>({
    location: [],

    whatsappNotify: true,
    emailNotify: true,
    newProperty: true,
    newRequirements: true,
    dailyTransaction: { personal: true, dld: false },
    weeklyReport: { personal: false, dld: false },
    monthlyReport: { personal: false, dld: false },

    user: '',
  });

  //@ts-ignore
  const Control = ({ children, ...props }) => {
    const { selected } = props.selectProps;

    const style = {
      cursor: 'pointer',
      marginLeft: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    const [checked, setChecked] = useState(true);

    return (
      //@ts-ignore
      <components.Control {...props}>
        <span onMouseDown={() => {}} style={style}>
          {/* <img src="/assets/img/locationicon.svg" alt="location" className="" /> */}
          {selected && selected !== '' && (
            <CheckIcon style={{ color: '#D12D34', fontSize: '18px' }} />
          )}
        </span>
        {children}
      </components.Control>
    );
  };

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="circularprogress-span">
          {' '}
          <CircularProgress
            className="circularprogress"
            size={20}
            sx={{ color: '#D12D34' }}
          />
          Loading
        </span>
      </components.NoOptionsMessage>
    );
  };

  const handleChangeRadios = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (formError.accountType) {
      setformError({ ...formError, accountType: false });
    }

    setUserDetails({ ...userDetails, accountType: event.target.value });
    setAccountType(event.target.value);
  };

  //handle submit form
  useEffect(() => {
    if (accountType) {
      setUserDetails({ ...userDetails, accountType: accountType });
    }
  }, [accountType]);

  const [anchorbudget, setAnchorBudget] = useState<null | HTMLElement>(null);

  const handleClickBudget = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorBudget(anchorbudget ? null : event.currentTarget);
  };
  const openbudget = Boolean(anchorbudget);
  const idbudget = openbudget ? 'simple-popper' : undefined;

  const handleSubmit = async () => {
    if (userDetails.accountType === 'Individual') {
      if (userDetails.location.length === 0) {
        openModal(
          <DialogModals
            action={() => {}}
            title="Empty Values!"
            type="error"
            message={`You Need to select at least one location to save data <br/>  Location: ${
              userDetails.location.length > 0 ? '✅' : '❌'
            }`}
          />
        );
      } else if (
        [
          ...Object.values(userDetails.dailyTransaction),
          ...Object.values(userDetails.weeklyReport),
          userDetails.newProperty,
          userDetails.newRequirements,
          ...Object.values(userDetails.monthlyReport),
        ].includes(true) &&
        !userDetails.emailNotify &&
        !userDetails.whatsappNotify
      ) {
        openModal(
          <DialogModals
            action={() => {}}
            title="Empty Choices!"
            type="error"
            message={`You Need to Choose at least one medium for notifications <br/> Email: ${
              userDetails.emailNotify ? '✅' : '❌'
            } <br/> Whatsapp: ${userDetails.whatsappNotify ? '✅' : '❌'}`}
          />
        );
      } else if (
        ![
          ...Object.values(userDetails.dailyTransaction),
          ...Object.values(userDetails.weeklyReport),
          userDetails.newProperty,
          userDetails.newRequirements,
          ...Object.values(userDetails.monthlyReport),
        ].includes(true) &&
        (userDetails.emailNotify || userDetails.whatsappNotify)
      ) {
        openModal(
          <DialogModals
            action={() => {}}
            title="Empty Choices!"
            type="error"
            message={`You Need to Choose at least one type Notification to receive from  ${
              userDetails.emailNotify ? 'Email medium' : 'Whatsapp Medium'
            }`}
          />
        );
      } else {
        setLoadingState(true);
        const validatederror = validateUserDetails();

        if (validatederror) {
          // console.log(brokerDetails, "details");

          var bodyFormData = new FormData();
          if (selectedBusinessCard) {
            bodyFormData.append('location', selectedBusinessCard);
          }

          if (selectedReraCard) {
            bodyFormData.append('project', selectedReraCard);
          }
          if (selectedFile) {
            bodyFormData.append('picture', selectedFile);
          }
          bodyFormData.append('data', JSON.stringify(userDetails));
          const resp = await api.post('/auth/register-details', bodyFormData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          if (resp.status === 200) {
            // const resp = await api.post("/auth/login-user", brokerDetails);
            // if (resp.data.message === "success") {
            //   Swal.fire(
            //     "Details Submitted ",
            //     "Thank you for submitting the details, An admin will check your details and activate your account after verification",
            //     "success"
            //   );
            //   Cookies.set("userData", JSON.stringify(resp.data));
            // } else {
            //   Swal.fire(
            //     "Failed",
            //     "Sorry, Details Could Not be Submitted",
            //     "error"
            //   );
            // }
            openModal(
              <DialogModals
                action={() => router.push('/')}
                title=""
                type="success"
                label="Back to Homepage"
                message={`Thank you for submitting your details. Your account will undergo verification and will be activated within 48-72 hours by our admin team.`}
              />
            );
            // Cookies.remove('userDetails');
            // Cookies.set("userData", JSON.stringify(resp.data));
          } else if (resp.status === 201) {
            openModal(
              <DialogModals
                action={() => {}}
                title="Details Already Submitted"
                type="warning"
                message={``}
              />
            );
          } else {
            openModal(
              <DialogModals
                action={() => {}}
                title="Failed!!"
                type="error"
                message={`Sorry, Details Could Not be Submitted`}
              />
            );
          }
        }

        setLoadingState(false);
      }
    } else {
      setLoadingState(true);
      const validatederror = validateCompanyDetails();
      if (validatederror) {
        const resp = await api.post('/company/register', companyDetails);

        if (resp.status === 200) {
          openModal(
            <DialogModals
              action={() => {}}
              title="Details Submitted "
              type="success"
              message={`Thank you for registering. We will review your application carefully to maintain our community standards and notify you upon approval.

If you have any questions, feedback, or special requests, feel free to contact us at support@brokerterminal.com. Please include your reference number: ${resp.data.rid}, if available, for a quicker response.`}
            />
          );
          router.reload();
          // Cookies.set("userData", JSON.stringify(resp.data));
        } else if (resp.status === 201) {
          openModal(
            <DialogModals
              action={() => {}}
              title="Details Already Submitted!"
              type="warning"
              message={resp.data.message}
            />
          );
        } else {
          openModal(
            <DialogModals
              action={() => {}}
              title="Failed"
              type="error"
              message={'Sorry, Details Could Not be Submitted'}
            />
          );
        }
      }
      setLoadingState(false);
    }
    setLoadingState(false);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      const targetElement = document.getElementById('paginateToReg');

      if (targetElement) {
        const offset = -120; // You can adjust this value as needed
        const targetPosition =
          targetElement.getBoundingClientRect().top +
          window.pageYOffset +
          offset;
        window.scrollTo({ top: targetPosition, behavior: 'smooth' });
      }
    };
    if (activeStep > 1) handleRouteChange();
  }, [activeStep]);
  return (
    <div
      className={styles.registerMainContainer_main_details}
      style={{ position: 'static' }}
    >
      <header
        className={styles.registerMainContainer_main_details_head}
        id="paginateToReg"
      >
        <div className={styles.registerMainContainer_main_details_head_top}>
          {activeStep === 1 && <h1>Create new account</h1>}
          {accountType === 'Individual' && (
            <TerminalSteppers
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              label="steps"
              count={5}
            />
          )}
        </div>
        {/* <p>
          Already have an account?
          <Link href={'/login'}>Log In</Link>
        </p> */}
      </header>
      {/* {accountType === 'Company' && (
        <aside className={styles.registerMainContainer_main_details_form}>
          <div
            className={styles.registerMainContainer_main_details_form_radios}
          >
            <FormControl color="primary">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                row
                onChange={handleChangeRadios}
                value={companyDetails.accountType}
              >
                <FormControlLabel
                  value="Individual"
                  name="accountType"
                  control={<Radio />}
                  checked={companyDetails.accountType === 'Individual'}
                  label={
                    <span
                      style={{ fontSize: '14px', color: 'var(--main-text)' }}
                    >
                      Individual broker
                    </span>
                  }
                />
                <FormControlLabel
                  value="Company"
                  name="accountType"
                  checked={companyDetails.accountType === 'Company'}
                  control={<Radio />}
                  label={
                    <span
                      style={{ fontSize: '14px', color: 'var(--main-text)' }}
                    >
                      Company account
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div
            className={styles.registerMainContainer_main_details_form_inputs}
          >
            <label htmlFor="registercompanyName">
              <>Company Name</>{' '}
            </label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputs_border
              }
            >
              <input
                autoFocus={false}
                autoComplete="off"
                style={{
                  border: companyFormError.name
                    ? '1px solid var(--primary)'
                    : 'none',
                }}
                id="registercompanyName"
                placeholder="Enter your Company Name"
                type="text"
                name="name"
                value={companyDetails.name}
                onChange={(e) => {
                  if (companyFormError.name) {
                    setcompanyFormError({ ...companyFormError, name: false });
                  }
                  handleInputCompanyChange(e);
                }}
              />
            </div>
            {companyFormError.name &&
              companyDetails.name &&
              companyDetails.name.length > 0 && (
                <nav
                  onClick={() => {
                    setcompanyFormError({ ...companyFormError, name: false });
                    handleInputClearCompany('name', '');
                  }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {companyFormError.name ? <span>Enter a valid Name.</span> : <></>}
          </div>
          <div
            className={styles.registerMainContainer_main_details_form_inputs}
          >
            <label htmlFor="registerTradeLiscence">
              <>Trade License Number</>
            </label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputs_border
              }
            >
              <input
                autoFocus={false}
                autoComplete="off"
                style={{
                  border: companyFormError.tradeLicenseNumber
                    ? '1px solid var(--primary)'
                    : 'none',
                }}
                id="registerTradeLiscence"
                placeholder="Enter your Trade License Number"
                type="text"
                name="tradeLicenseNumber"
                value={companyDetails.tradeLicenseNumber}
                onChange={(e) => {
                  if (companyFormError.tradeLicenseNumber) {
                    setcompanyFormError({
                      ...companyFormError,
                      tradeLicenseNumber: false,
                    });
                  }
                  handleInputCompanyChange(e);
                }}
              />
            </div>
            {companyFormError.tradeLicenseNumber &&
              companyDetails.tradeLicenseNumber &&
              companyDetails.tradeLicenseNumber.length > 0 && (
                <nav
                  onClick={() => {
                    setcompanyFormError({
                      ...companyFormError,
                      tradeLicenseNumber: false,
                    });
                    //@ts-ignore
                    setUserDetails((prevCompanyDetails: CompanyDetails) => ({
                      ...prevCompanyDetails,
                      tradeLicenseNumber: '',
                    }));
                  }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {companyFormError.tradeLicenseNumber ? (
              <span>Enter a valid Trade License Number </span>
            ) : (
              <></>
            )}
          </div>
          <div
            className={styles.registerMainContainer_main_details_form_inputs}
          >
            <label htmlFor="registerEmail">
              Company Email (Ex: email@company.com)
            </label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputs_border
              }
            >
              <input
                autoFocus={false}
                autoComplete="off"
                style={{
                  border: companyFormError.email
                    ? '1px solid var(--primary)'
                    : 'none',
                }}
                id="registerEmail"
                placeholder="Enter your Email"
                type="email"
                name="email"
                value={companyDetails.email}
                onChange={(e) => {
                  if (companyFormError.email) {
                    setcompanyFormError({
                      ...companyFormError,
                      email: false,
                    });
                  }

                  handleInputCompanyChange(e);
                }}
              />
            </div>
            {companyFormError.email &&
              companyDetails.email &&
              companyDetails.email.length > 0 && (
                <nav
                  onClick={() => {
                    setcompanyFormError({
                      ...companyFormError,
                      email: false,
                    });
                    handleInputClearCompany('email', '');
                  }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {companyFormError.email ? (
              <span>Enter a valid Company Email.</span>
            ) : (
              <></>
            )}
          </div>
          <div
            className={styles.registerMainContainer_main_details_form_inputs}
          >
            <label htmlFor="registerCompanyPn">WhatsApp No</label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputs_border
              }
            >
              <PhoneInput
                id="registerCompanyPn"
                placeholder="Enter your Number"
                style={{
                  border: companyFormError.phoneNumber
                    ? '1px solid var(--primary)'
                    : 'none',
                  borderRadius: '48px',
                  padding: '0 20px',
                }}
                value={companyDetails.phoneNumber}
                defaultCountry="AE"
                countryCallingCodeEditable={false}
                onChange={(e) => {
                  if (companyFormError.phoneNumber) {
                    setcompanyFormError({
                      ...companyFormError,
                      phoneNumber: false,
                    });
                  }
                  setCompanyDetails({
                    ...companyDetails,
                    phoneNumber: String(e),
                  });
                }}
              />
            </div>
            {companyFormError.phoneNumber &&
              companyDetails.phoneNumber &&
              companyDetails.phoneNumber.length > 0 && (
                <nav
                  onClick={() => {
                    setcompanyFormError({
                      ...companyFormError,
                      phoneNumber: false,
                    });
                    handleInputClearCompany('phoneNumber', undefined);
                  }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {companyFormError.phoneNumber ? (
              <span>Enter a valid phoneNumber</span>
            ) : (
              <></>
            )}
          </div>
          <div
            className={
              styles.registerMainContainer_main_details_form_inputsmall
            }
          >
            <label htmlFor="registerBrnCrm">CRM Name</label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputs_border
              }
            >
              <input
                autoFocus={false}
                autoComplete="off"
                style={{
                  border: companyFormError.crmName
                    ? '1px solid var(--primary)'
                    : 'none',
                }}
                id="registerBrnCrm"
                placeholder="Enter your CRM Name"
                type="text"
                name="crmName"
                value={companyDetails.crmName}
                onChange={(e) => {
                  if (formError.brn) {
                    setcompanyFormError({
                      ...companyFormError,
                      crmName: false,
                    });
                  }

                  handleInputCompanyChange(e);
                }}
              />
            </div>
            {companyFormError.crmName &&
              companyDetails.crmName &&
              companyDetails.crmName !== undefined &&
              //@ts-ignore
              companyDetails.crmName.length > 0 && (
                <nav
                  onClick={() => {
                    setcompanyFormError({
                      ...companyFormError,
                      crmName: false,
                    });
                    handleInputClear('crmName', undefined);
                  }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {companyFormError.crmName ? (
              <span>Enter a valid CRM Name</span>
            ) : (
              <></>
            )}
          </div>
          <div
            className={
              styles.registerMainContainer_main_details_form_inputsmall
            }
          >
            <label htmlFor="registerBrnCrmXML">CRM URL (XML Link)</label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputs_border
              }
            >
              <input
                autoFocus={false}
                autoComplete="off"
                style={{
                  border: companyFormError.crmUrl
                    ? '1px solid var(--primary)'
                    : 'none',
                }}
                id="registerBrnCrmXML"
                placeholder="Enter your CRM URL"
                type="text"
                name="crmUrl"
                value={companyDetails.crmUrl}
                onChange={(e) => {
                  if (formError.brn) {
                    setcompanyFormError({
                      ...companyFormError,
                      crmUrl: false,
                    });
                  }

                  handleInputCompanyChange(e);
                }}
              />
            </div>
            {companyFormError.crmUrl &&
              companyDetails.crmUrl &&
              companyDetails.crmUrl !== undefined &&
              //@ts-ignore
              companyDetails.crmUrl.length > 0 && (
                <nav
                  onClick={() => {
                    setcompanyFormError({
                      ...companyFormError,
                      crmUrl: false,
                    });
                    handleInputClear('crmUrl', undefined);
                  }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {companyFormError.crmUrl ? (
              <span>Enter a valid CRM URL</span>
            ) : (
              <></>
            )}
          </div>
        </aside>
      )} */}
      {accountType !== 'Individual' && (
        <aside className={styles.registerMainContainer_main_details_form}>
          <div
            className={styles.registerMainContainer_main_details_form_inputs}
          >
            <label htmlFor="registercompanyName">
              <>{accountType} Name</>{' '}
            </label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputs_border
              }
            >
              <input
                autoFocus={false}
                autoComplete="off"
                style={{
                  border: companyFormError.name
                    ? '1px solid var(--primary)'
                    : 'none',
                }}
                id="registercompanyName"
                placeholder={`Enter the ${accountType} Name`}
                type="text"
                name="name"
                value={companyDetails.name}
                onChange={(e) => {
                  if (companyFormError.name) {
                    setcompanyFormError({ ...companyFormError, name: false });
                  }
                  handleInputCompanyChange(e);
                }}
              />
            </div>
            {companyFormError.name &&
              companyDetails.name &&
              companyDetails.name.length > 0 && (
                <nav
                  onClick={() => {
                    setcompanyFormError({ ...companyFormError, name: false });
                    handleInputClearCompany('name', '');
                  }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {companyFormError.name ? <span>Enter a valid Name.</span> : <></>}
          </div>

          <div
            className={styles.registerMainContainer_main_details_form_inputs}
          >
            <label htmlFor="registerEmail">Email (Ex: email@example.com)</label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputs_border
              }
            >
              <input
                autoFocus={false}
                autoComplete="off"
                style={{
                  border: companyFormError.email
                    ? '1px solid var(--primary)'
                    : 'none',
                }}
                id="registerEmail"
                placeholder="Enter your Email"
                type="email"
                name="email"
                value={companyDetails.email}
                onChange={(e) => {
                  if (companyFormError.email) {
                    setcompanyFormError({
                      ...companyFormError,
                      email: false,
                    });
                  }

                  handleInputCompanyChange(e);
                }}
              />
            </div>
            {companyFormError.email &&
              companyDetails.email &&
              companyDetails.email.length > 0 && (
                <nav
                  onClick={() => {
                    setcompanyFormError({
                      ...companyFormError,
                      email: false,
                    });
                    handleInputClearCompany('email', '');
                  }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {companyFormError.email ? (
              <span>Enter a valid Company Email.</span>
            ) : (
              <></>
            )}
          </div>
          <div
            className={styles.registerMainContainer_main_details_form_inputs}
          >
            <label htmlFor="registerCompanyPn">Phone Number</label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputs_border
              }
            >
              <PhoneInput
                id="registerCompanyPn"
                placeholder="Enter your Number"
                style={{
                  border: companyFormError.phoneNumber
                    ? '1px solid var(--primary)'
                    : 'none',
                  borderRadius: '48px',
                  padding: '0 20px',
                }}
                value={companyDetails.phoneNumber}
                defaultCountry="AE"
                countryCallingCodeEditable={false}
                onChange={(e) => {
                  if (companyFormError.phoneNumber) {
                    setcompanyFormError({
                      ...companyFormError,
                      phoneNumber: false,
                    });
                  }
                  setCompanyDetails({
                    ...companyDetails,
                    phoneNumber: String(e),
                  });
                }}
              />
            </div>
            {companyFormError.phoneNumber &&
              companyDetails.phoneNumber &&
              companyDetails.phoneNumber.length > 0 && (
                <nav
                  onClick={() => {
                    setcompanyFormError({
                      ...companyFormError,
                      phoneNumber: false,
                    });
                    handleInputClearCompany('phoneNumber', undefined);
                  }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {companyFormError.phoneNumber ? (
              <span>Enter a valid phoneNumber</span>
            ) : (
              <></>
            )}
          </div>
          <div
            className={styles.registerMainContainer_main_details_form_inputs}
          >
            <label htmlFor="registerWebsite">Website (optional)</label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputs_border
              }
            >
              <input
                autoFocus={false}
                autoComplete="off"
                style={{
                  border: companyFormError.website
                    ? '1px solid var(--primary)'
                    : 'none',
                }}
                id="registerWebsite"
                placeholder="Enter your Website"
                type="text"
                name="website"
                value={companyDetails.website}
                onChange={(e) => {
                  if (companyFormError.website) {
                    setcompanyFormError({
                      ...companyFormError,
                      website: false,
                    });
                  }

                  handleInputCompanyChange(e);
                }}
              />
            </div>
            {companyFormError.website &&
              companyDetails.website &&
              companyDetails.website.length > 0 && (
                <nav
                  onClick={() => {
                    setcompanyFormError({
                      ...companyFormError,
                      website: false,
                    });
                    handleInputClearCompany('website', '');
                  }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {companyFormError.website ? (
              <span>Enter a valid Company website.</span>
            ) : (
              <></>
            )}
          </div>
          <div
            className={styles.registerMainContainer_main_details_form_inputs}
          >
            <label htmlFor="registerWebsite">
              Could you tell us a bit more about yourself and why you're
              interested in joining BrokerTerminal? (optional){' '}
            </label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputs_border
              }
            >
              <textarea
                rows={3}
                autoFocus={false}
                autoComplete="off"
                style={{
                  border: companyFormError.website
                    ? '1px solid var(--primary)'
                    : 'none',
                }}
                id="registerWebsite"
                placeholder="Explain Your Interest"
                name="description"
                value={companyDetails.description}
                onChange={(e) => {
                  if (companyFormError.description) {
                    setcompanyFormError({
                      ...companyFormError,
                      description: false,
                    });
                  }

                  setCompanyDetails({
                    ...companyDetails,
                    description: e.target.value,
                  });
                }}
              />
            </div>
            {companyFormError.description &&
              companyDetails.description &&
              companyDetails.description.length > 0 && (
                <nav
                  onClick={() => {
                    setcompanyFormError({
                      ...companyFormError,
                      description: false,
                    });
                    handleInputClearCompany('description', '');
                  }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {companyFormError.description ? (
              <span>Enter a valid Interest.</span>
            ) : (
              <></>
            )}
          </div>
        </aside>
      )}
      {activeStep === 1 && accountType === 'Individual' && (
        <aside className={styles.registerMainContainer_main_details_form}>
          {/* <div
            className={styles.registerMainContainer_main_details_form_radios}
          >
            <FormControl color="primary">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                row
                onChange={handleChangeRadios}
                value={userDetails.accountType}
              >
                <FormControlLabel
                  value="Individual"
                  name="accountType"
                  control={<Radio />}
                  checked={userDetails.accountType === 'Individual'}
                  label={
                    <span
                      style={{ fontSize: '14px', color: 'var(--main-text)' }}
                    >
                      Individual broker
                    </span>
                  }
                />
                <FormControlLabel
                  value="Company"
                  name="accountType"
                  checked={userDetails.accountType === 'Company'}
                  control={<Radio />}
                  label={
                    <span
                      style={{ fontSize: '14px', color: 'var(--main-text)' }}
                    >
                      Company account
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>
          </div> */}
          <h2 style={{ width: '100%' }}>User Details</h2>

          <div
            className={
              styles.registerMainContainer_main_details_form_inputsmall
            }
          >
            <label htmlFor="registerfirstName">
              <>First Name</>{' '}
            </label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputsmall_border
              }
            >
              <input
                autoFocus={false}
                autoComplete="off"
                style={{
                  border: formError.firstName
                    ? '1px solid var(--primary)'
                    : 'none',
                }}
                id="registerfirstName"
                placeholder="Enter your First Name"
                type="text"
                name="firstName"
                value={userDetails.firstName}
                onChange={(e) => {
                  if (formError.firstName) {
                    setformError({ ...formError, firstName: false });
                  }
                  handleInputChange(e);
                }}
              />
            </div>
            {formError.firstName &&
              userDetails.firstName &&
              userDetails.firstName.length > 0 && (
                <nav
                  onClick={() => {
                    setformError({ ...formError, firstName: false });
                    handleInputClear('firstName', '');
                  }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {formError.firstName ? (
              <span>Enter a valid First Name.</span>
            ) : (
              <></>
            )}
          </div>
          <div
            className={
              styles.registerMainContainer_main_details_form_inputsmall
            }
          >
            <label htmlFor="registerLastName">
              <>Last Name</>
            </label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputsmall_border
              }
            >
              <input
                autoFocus={false}
                autoComplete="off"
                style={{
                  border: formError.lastName
                    ? '1px solid var(--primary)'
                    : 'none',
                }}
                id="registerLastName"
                placeholder="Enter your Last Name"
                type="text"
                name="lastName"
                value={userDetails.lastName}
                onChange={(e) => {
                  if (formError.lastName) {
                    setformError({ ...formError, lastName: false });
                  }
                  handleInputChange(e);
                }}
              />
            </div>
            {formError.lastName &&
              userDetails.lastName &&
              userDetails.lastName.length > 0 && (
                <nav
                  onClick={() => {
                    setformError({ ...formError, lastName: false });
                    //@ts-ignore
                    setUserDetails((prevUserDetails: UserDetails) => ({
                      ...prevUserDetails,
                      lastName: '',
                    }));
                  }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {formError.lastName ? <span>Enter a valid Last Name</span> : <></>}
          </div>
          <div
            className={styles.registerMainContainer_main_details_form_inputs}
          >
            <label htmlFor="registerEmail">Email</label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputs_border
              }
            >
              <input
                autoFocus={false}
                autoComplete="off"
                style={{
                  border: formError.email ? '1px solid var(--primary)' : 'none',
                }}
                id="registerEmail"
                placeholder="Enter your Email"
                type="email"
                name="email"
                value={userDetails.email}
                onChange={(e) => {
                  if (formError.email) {
                    setformError({ ...formError, email: false });
                  }

                  handleInputChange(e);
                }}
              />
            </div>
            {formError.email &&
              userDetails.email &&
              userDetails.email.length > 0 && (
                <nav
                  onClick={() => {
                    setformError({ ...formError, email: false });
                    handleInputClear('email', '');
                  }}
                  style={{ top: '38px' }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {formError.email ? <span>Enter a valid Company Email</span> : <></>}
          </div>
          <div
            className={
              styles.registerMainContainer_main_details_form_inputsmall
            }
          >
            <label htmlFor="registerBrn">Select One or More Languages</label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputsmall_nb
              }
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <BtSelectField
                  isMulti
                  dropdown={true}
                  options={languageOptions}
                  icon={<MessageIconSvg color="var(--primary)" />}
                  //@ts-ignore
                  value={userDetails.languages?.map((bd) => ({
                    value: bd,
                    label: bd,
                  }))}
                  className={
                    styles.Maincontainer_searchinp_detailbox_selectbox_selectsn
                  }
                  setselectValue={(e) => {
                    if (formError.languages) {
                      setformError({
                        ...formError,
                        languages: false,
                      });
                    }
                    //@ts-ignore
                    setUserDetails({
                      ...userDetails,
                      //@ts-ignore
                      languages: e.map((es) => es.value),
                    });
                  }}
                  label={''}
                />
                <section>
                  {userDetails.languages.map((lang: string, index: number) => (
                    <aside key={index}>
                      <span>{lang}</span>
                      <span
                        onClick={() =>
                          setUserDetails({
                            ...userDetails,
                            //@ts-ignore
                            languages: userDetails.languages.filter(
                              (ln) => ln !== lang
                            ),
                          })
                        }
                      >
                        <Close />
                      </span>
                    </aside>
                  ))}
                </section>
              </div>
            </div>
            {formError.languages &&
              userDetails.languages &&
              userDetails.languages.length > 0 && (
                <nav
                  onClick={() => {
                    setformError({ ...formError, languages: false });
                    handleInputClear('languages', undefined);
                  }}
                  style={{ position: 'absolute', top: '40px', right: '10px' }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {formError.languages ? <span>Enter a valid languages</span> : <></>}
          </div>
          {/* <div
            className={
              styles.registerMainContainer_main_details_form_inputsmall
            }
          >
            <label htmlFor="registerBrn">Broker Registration Number</label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputsmall_border
              }
            >
              <input
                autoFocus={false}
                autoComplete="off"
                style={{
                  border: formError.brn ? '1px solid var(--primary)' : 'none',
                }}
                id="registerBrn"
                placeholder="Enter your BRN"
                type="number"
                name="brn"
                value={userDetails.brn}
                onChange={(e) => {
                  if (formError.brn) {
                    setformError({ ...formError, brn: false });
                  }
                  if (
                    /^\d+$/.test(e.target.value) ||
                    Number(e.target.value) >= 0
                  ) {
                    handleInputChange(e);
                  }
                }}
              />
            </div>
            {formError.brn &&
              userDetails.brn &&
              userDetails.brn !== undefined &&
              //@ts-ignore
              userDetails.brn > 0 && (
                <nav
                  onClick={() => {
                    setformError({ ...formError, brn: false });
                    handleInputClear('brn', undefined);
                  }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {formError.brn ? (
              <span>Enter a valid Broker Registration Number</span>
            ) : (
              <></>
            )}
          </div> */}
          <div
            className={styles.registerMainContainer_main_details_form_inputsmal}
          >
            <label htmlFor="registerBrn">WhatsApp No</label>
            <div
              className={
                styles.registerMainContainer_main_details_form_inputsmal_border
              }
            >
              <PhoneInput
                placeholder="Enter your Number"
                style={{
                  border: formError.phoneNumber
                    ? '1px solid var(--primary)'
                    : 'none',
                  borderRadius: '48px',
                  padding: '0 20px',
                }}
                value={userDetails.phoneNumber}
                defaultCountry="AE"
                countryCallingCodeEditable={false}
                onChange={(e) => {
                  if (formError.phoneNumber) {
                    setformError({ ...formError, phoneNumber: false });
                  }
                  setUserDetails({
                    ...userDetails,
                    phoneNumber: String(e),
                  });
                }}
              />
            </div>
            {formError.phoneNumber &&
              userDetails.phoneNumber &&
              userDetails.phoneNumber.length > 0 && (
                <nav
                  onClick={() => {
                    setformError({ ...formError, phoneNumber: false });
                    handleInputClear('phoneNumber', undefined);
                  }}
                  style={{ position: 'absolute', top: '40px', right: '10px' }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
            {formError.phoneNumber ? (
              <span>Enter a valid phoneNumber</span>
            ) : (
              <></>
            )}
          </div>
        </aside>
      )}
      {activeStep === 2 &&
        !displayNewPassword &&
        accountType === 'Individual' && (
          <div className={styles.MainContainer_subContainer}>
            <span className={styles.MainContainer_subContainer_heading}>
              Enter the Verification Code Sent to <br />
              <p style={{ color: '#d12d34' }}>{userDetails.email}</p>
            </span>
            <div
              style={{
                width: '100%',
                height: '200px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {' '}
              Enter Code Here
              <ReactCodeInput
                fields={6}
                className="mobileVerify"
                onComplete={handleSubmitCode}
              />
              {formError.code ? (
                <span style={{ color: '#d12d34' }}>
                  Please enter the valid code.
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      {activeStep === 2 &&
        displayNewPassword &&
        accountType === 'Individual' && (
          <div className={styles.MainContainer_subContainer}>
            <span className={styles.MainContainer_subContainer_heading}>
              Create a Password for Your Account
            </span>
            <div className={styles.mainContainer_main_details_main_inputs}>
              <label htmlFor="loginPassword">
                <>
                  Please provide a valid password with a minimum length of 6
                  characters.
                </>{' '}
              </label>{' '}
              <div
                className={styles.mainContainer_main_details_main_inputs_border}
              >
                <input
                  autoFocus={false}
                  autoComplete="off"
                  style={{
                    border: formError.password
                      ? '1px solid var(--primary)'
                      : 'none',
                  }}
                  id="loginPassword"
                  type="password"
                  placeholder="Enter your new Password"
                  value={userDetails.password}
                  onChange={(e) => {
                    if (formError.password) {
                      setformError({ ...formError, password: false });
                    }
                    setUserDetails({
                      ...userDetails,
                      password: e.target.value,
                    });
                  }}
                />
              </div>
              {formError.password && userDetails.password.length > 0 && (
                <nav
                  onClick={() => {
                    setformError({
                      ...formError,
                      password: false,
                    });
                    setUserDetails({ ...userDetails, password: '' });
                  }}
                >
                  <ErrorIconSvg />
                </nav>
              )}
              {formError.password ? (
                <span>
                  Invalid Password , Password must be at least 6 characters.
                </span>
              ) : (
                <></>
              )}{' '}
            </div>
            <div className={styles.mainContainer_main_details_main_inputs}>
              <label htmlFor="loginPassword">
                <>Confirm Password</>{' '}
              </label>{' '}
              <div
                className={styles.mainContainer_main_details_main_inputs_border}
              >
                <input
                  autoFocus={false}
                  autoComplete="off"
                  style={{
                    border: formError.confirmPassword
                      ? '1px solid var(--primary)'
                      : 'none',
                  }}
                  id="loginPassword"
                  type="password"
                  placeholder="Confirm your new Password"
                  value={userDetails.confirmPassword}
                  onChange={(e) => {
                    if (formError.confirmPassword) {
                      setformError({
                        ...formError,
                        confirmPassword: false,
                      });
                    }
                    setUserDetails({
                      ...userDetails,
                      confirmPassword: e.target.value,
                    });
                  }}
                />{' '}
              </div>
              {formError.confirmPassword &&
                userDetails.confirmPassword.length > 0 && (
                  <nav
                    style={{ top: '38px' }}
                    onClick={() => {
                      setformError({
                        ...formError,
                        confirmPassword: false,
                      });
                      setUserDetails({
                        ...userDetails,
                        confirmPassword: '',
                      });
                    }}
                  >
                    <ErrorIconSvg />
                  </nav>
                )}{' '}
              {formError.confirmPassword ? (
                <span>
                  Confirm Password must match the Password and be at least 6
                  characters long.
                </span>
              ) : (
                <></>
              )}{' '}
            </div>
          </div>
        )}
      {activeStep === 3 && accountType === 'Individual' && (
        <div className={styles.MainContainer_subContainer}>
          <span className={styles.MainContainer_subContainer_heading}>
            Upload Your Documents/Images
          </span>
          <div className={styles.profileUpdate_main_inputs}>
            {/* <span className={styles.MainContainer_subContainer_box_headings}>
               
              </span> */}
            <section>
              <aside>
                <label htmlFor="updatePhoto">
                  <span
                    style={{
                      display: 'flex',
                      gap: '4px',
                      color: 'var(--main-text)',
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'var(--main-text)',
                      }}
                    >
                      Upload Profile Photo
                      <div style={{ width: 'max-content' }}>
                        <img
                          style={{
                            marginBottom: '-4px',
                          }}
                          aria-describedby={idPhoto}
                          onClick={(e) => handleClickPhoto(e)}
                          src="https://brokerterminal.sgp1.cdn.digitaloceanspaces.com/icons/info.png"
                          alt="info"
                          width={'16'}
                          height={'auto'}
                        />{' '}
                        More Information
                      </div>
                    </span>
                    <Popper
                      id={idPhoto}
                      open={openPhoto}
                      anchorEl={anchorPhoto}
                      style={{ zIndex: 99999 }}
                    >
                      <Box
                        sx={{
                          padding: '0 16px',
                          width: '320px',
                          zIndex: 99999,
                        }}
                      >
                        {
                          //@ts-ignore
                          <ClickAwayListener
                            //@ts-ignore
                            onClickAway={(e) => handleClickPhoto(e)}
                          >
                            <Alert severity="warning">
                              <div
                                onClick={handleClickPhoto}
                                style={{
                                  position: 'absolute',
                                  right: '20px',
                                  top: '5px',
                                  cursor: 'pointer',

                                  borderRadius: '50%',
                                  width: '30px',
                                  height: '30px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Close sx={{ color: '#d12d34' }} />
                              </div>
                              {`Upon selection, your profile photo will be featured throughout our entire website. To make the best impression on potential clients, please ensure that you upload a high-quality, business-class photo. Casual or unprofessional photos will not be considered, and profiles without an approved photo will not be approved for display. Your photo is your first impression—make it count!`}
                            </Alert>
                          </ClickAwayListener>
                        }
                      </Box>
                    </Popper>
                  </span>
                </label>

                {/* {userDetails?.profilePic &&
                      userDetails?.profilePic !== '' && (
                        <img
                          src={userDetails?.profilePic}
                          width="100px"
                          height={'auto'}
                        />
                      )} */}

                <br />
                <Button
                  id="updatePhoto"
                  variant="contained"
                  component="label"
                  color="primary"
                  sx={{ width: '150px' }}
                  className={styles.profileUpdate_main_inputs_ibutton}
                >
                  Select Image
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={changeHandler}
                  />
                </Button>
              </aside>
              <aside>
                {selectedFile && (
                  <img
                    width="100px"
                    height={'auto'}
                    src={
                      //@ts-ignore
                      URL.createObjectURL(selectedFile)
                    }
                    alt=""
                  />
                )}
              </aside>
            </section>

            <span style={{ color: '#000' }}>
              {
                //@ts-ignore
                selectedFile && <p>{selectedFile?.name}</p>
              }
            </span>
            {formError.profilePic ? (
              <span style={{ margin: '5px 0', width: '100%' }}>
                Having a Profile Pic is Mandatory For agents
              </span>
            ) : (
              <></>
            )}
          </div>
          <hr />
          <div className={styles.profileUpdate_main_inputs}>
            <section>
              <aside>
                <label htmlFor="updateRera">
                  <span
                    style={{
                      display: 'flex',
                      gap: '4px',
                      color: 'var(--main-text)',
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'var(--main-text)',
                      }}
                    >
                      Upload RERA Card (Mandatory)
                      <div style={{ width: 'max-content' }}>
                        <img
                          style={{
                            marginBottom: '-4px',
                          }}
                          aria-describedby={idRera}
                          onClick={(e) => handleClickRera(e)}
                          src="https://brokerterminal.sgp1.cdn.digitaloceanspaces.com/icons/info.png"
                          alt="info"
                          width={'16'}
                          height={'auto'}
                        />{' '}
                        More Information
                      </div>
                    </span>
                    <Popper
                      id={idRera}
                      open={openRera}
                      anchorEl={anchorRera}
                      style={{ zIndex: 99999 }}
                    >
                      <Box
                        sx={{
                          padding: '0 16px',
                          width: '320px',
                          zIndex: 99999,
                        }}
                      >
                        {
                          //@ts-ignore
                          <ClickAwayListener
                            //@ts-ignore
                            onClickAway={(e) => handleClickRera(e)}
                          >
                            <Alert severity="warning">
                              <div
                                onClick={handleClickRera}
                                style={{
                                  position: 'absolute',
                                  right: '20px',
                                  top: '5px',
                                  cursor: 'pointer',

                                  borderRadius: '50%',
                                  width: '30px',
                                  height: '30px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Close sx={{ color: '#d12d34' }} />
                              </div>
                              {`To verify your identity, we need your RERA (Real Estate Regulatory Authority) card. Accepted formats: PDF, JPG, screenshots, mobile screenshots. Ensure card visibility.

Non-compliance may result in account suspension.`}
                            </Alert>
                          </ClickAwayListener>
                        }
                      </Box>
                    </Popper>
                  </span>
                </label>

                <br />
                <Button
                  id="updateRera"
                  variant="contained"
                  component="label"
                  sx={{ width: '180px' }}
                  color="primary"
                  className={styles.profileUpdate_main_inputs_ibutton}
                >
                  Select ReraCard
                  <input
                    type="file"
                    accept="image/*,.pdf"
                    hidden
                    onChange={changeHandlerRera}
                  />
                </Button>
              </aside>
              <aside>
                {selectedReraCard &&
                  (selectedReraCard.type === 'application/pdf' ||
                  selectedReraCard.name.endsWith('.pdf') ? (
                    <></>
                  ) : (
                    <img
                      width="100px"
                      height={'auto'}
                      src={URL.createObjectURL(selectedReraCard)}
                      alt=""
                    />
                  ))}
                {/* or render pdf if its pdf */}
              </aside>
            </section>
            <span style={{ color: '#000' }}>
              {
                //@ts-ignore
                selectedReraCard && <p>{selectedReraCard?.name}</p>
              }
            </span>
            {formError.reraCard ? (
              <span style={{ margin: '5px 0' }}>
                Having a Rera card Linked is Mandatory For agents
              </span>
            ) : (
              <></>
            )}
          </div>
          <hr />
          <div className={styles.profileUpdate_main_inputs}>
            <section>
              <aside>
                {' '}
                <label htmlFor="updateBusiness">
                  <span
                    style={{
                      display: 'flex',
                      gap: '4px',
                      color: 'var(--main-text)',
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'var(--main-text)',
                      }}
                    >
                      Upload Business Card (Optional)
                    </span>
                  </span>
                </label>
                <br />
                <Button
                  sx={{ width: '220px' }}
                  id="updateBusiness"
                  variant="contained"
                  component="label"
                  color="primary"
                  className={styles.profileUpdate_main_inputs_ibutton}
                >
                  Select BusinessCard
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={changeHandlerBusiness}
                  />
                </Button>
              </aside>
              <aside>
                {selectedBusinessCard && (
                  <img
                    height={'auto'}
                    width={'100px'}
                    src={URL.createObjectURL(selectedBusinessCard)}
                    alt=""
                  />
                )}
              </aside>
            </section>

            <br />
            <span style={{ color: '#000' }}>
              {
                //@ts-ignore
                selectedBusinessCard && <p>{selectedBusinessCard?.name}</p>
              }
            </span>
          </div>{' '}
          <hr />
          {/* <button
              onClick={() => {
                if (!previousData) {
                  handlesaveDetails();
                } else {
                  handleUpdateDetails();
                }
              }}
              className={styles.MainContainer_subContainer_savebutton}
            >
              Save Details
            </button> */}
        </div>
      )}
      {activeStep === 4 && accountType === 'Individual' && (
        <div className={styles.MainContainer_subContainer}>
          {/* <span className={styles.MainContainer_subContainer_heading}>
              Area Specialist Form
            </span> */}
          <div className={styles.MainContainer_subContainer_box}>
            <span
              className={styles.MainContainer_subContainer_box_heading}
              style={{ fontWeight: '500' }}
            >
              Pick up to two price ranges for your property transactions as a
              broker:
              <div style={{ width: 'max-content' }}>
                <img
                  style={{
                    marginBottom: '-4px',
                  }}
                  aria-describedby={idbudget}
                  onClick={(e) => handleClickBudget(e)}
                  src="https://brokerterminal.sgp1.cdn.digitaloceanspaces.com/icons/info.png"
                  alt="info"
                  width={'16'}
                  height={'auto'}
                />{' '}
                More Information
              </div>
              <Popper
                id={idbudget}
                open={openbudget}
                anchorEl={anchorbudget}
                style={{ zIndex: 99999 }}
              >
                <Box
                  sx={{
                    padding: '0 16px',
                    width: '360px',
                    zIndex: 99999,
                  }}
                >
                  {
                    //@ts-ignore
                    <ClickAwayListener
                      //@ts-ignore
                      onClickAway={(e) => handleClickBudget(e)}
                    >
                      <Alert severity="warning">
                        <div
                          onClick={handleClickBudget}
                          style={{
                            position: 'absolute',
                            right: '20px',
                            top: '5px',
                            cursor: 'pointer',

                            borderRadius: '50%',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Close sx={{ color: '#d12d34' }} />
                        </div>
                        {` To ensure we connect you with the right audience of buyers and sellers, we kindly request you to update your profile by selecting up to two categories that align with your primary focus.

You can choose from the following property segments:

Affordable Properties (500K-5M)
Luxury Properties (5M-10M)
Premier Properties (10M-20M)
Elite Properties (20M-50M)
Ultra-Luxury Properties (50M+)
By making these selections, we will boost your profile's visibility for clients seeking properties within your chosen segments. This targeted approach will help you establish direct connections with potential buyers and sellers who match your expertise.`}
                      </Alert>
                    </ClickAwayListener>
                  }
                </Box>
              </Popper>
            </span>

            <div style={{ width: '100%' }}>
              <BtSelectField
                isClearable
                isMulti
                isSearchable={false}
                dropdown={true}
                options={budgetItems}
                icon={<DollarIconSvg color="var(--primary)" />}
                //@ts-ignore
                value={userDetails.budgetExpertise?.map((bd) => ({
                  value: bd,
                  label: bd,
                }))}
                className={
                  styles.Maincontainer_searchinp_detailbox_selectbox_selectsn
                }
                isOptionDisabled={userDetails.budgetExpertise?.length >= 2}
                setselectValue={(e) => {
                  if (formError.budgetExpertise) {
                    setformError({
                      ...formError,
                      budgetExpertise: false,
                    });
                  }
                  //@ts-ignore
                  setUserDetails({
                    ...userDetails,
                    //@ts-ignore
                    budgetExpertise: e.map((es) => es.value),
                  });
                }}
                label={'Ultra Luxury, Elite..'}
              />
              {formError.budgetExpertise ? (
                <span>Select a Budget Range.</span>
              ) : (
                <></>
              )}
              <section>
                {userDetails.budgetExpertise.map(
                  (lang: string, index: number) => (
                    <aside key={index}>
                      <span>
                        {
                          //@ts-ignore
                          budgetItems.filter((bd) => bd.value === lang)[0].label
                        }
                      </span>
                      <span
                        onClick={() =>
                          setUserDetails({
                            ...userDetails,
                            //@ts-ignore
                            budgetExpertise: userDetails.budgetExpertise.filter(
                              (ln) => ln !== lang
                            ),
                          })
                        }
                      >
                        <Close />
                      </span>
                    </aside>
                  )
                )}
              </section>
            </div>
          </div>
          <div className={styles.MainContainer_subContainer_box}>
            <span
              className={styles.MainContainer_subContainer_box_heading}
              style={{ fontWeight: '500' }}
            >
              Select Locations Based on your Area Specialization ( Max 5)
            </span>

            <div style={{ width: '100%' }}>
              <BtSelectField
                isClearable
                isMulti
                dropdown={true}
                options={
                  data &&
                  data.map((location: string) => ({
                    value: location,
                    label: location,
                  }))
                }
                icon={<LocationIconSvg color="var(--primary)" />}
                //@ts-ignore
                value={userDetails.location?.map((bd) => ({
                  value: bd,
                  label: bd,
                }))}
                className={
                  styles.Maincontainer_searchinp_detailbox_selectbox_selectsn
                }
                isOptionDisabled={userDetails.location?.length >= 5}
                setselectValue={(e) => {
                  if (formError.location) {
                    setformError({
                      ...formError,
                      location: false,
                    });
                  }
                  //@ts-ignore

                  setUserDetails({
                    ...userDetails,
                    //@ts-ignore
                    location: e.map((es) => es.value),
                  });
                }}
                label={'Eg. Downtown, Dubai Hills Estate'}
              />
              {formError.location ? (
                <span>Select atleast one Location</span>
              ) : (
                <></>
              )}
              <section>
                {userDetails.location.map((lang: string, index: number) => (
                  <aside key={index}>
                    <span>{lang}</span>
                    <span
                      onClick={() =>
                        setUserDetails({
                          ...userDetails,
                          //@ts-ignore
                          location: userDetails.location.filter(
                            (ln) => ln !== lang
                          ),
                        })
                      }
                    >
                      <Close />
                    </span>
                  </aside>
                ))}
              </section>
            </div>
          </div>{' '}
        </div>
      )}
      <footer className={styles.mainContainer_main_details_footer}>
        {accountType !== 'Individual' ? (
          <TerminalButton
            position="ll"
            width="100%"
            label="Submit"
            onClick={() => handleSubmit()}
          />
        ) : activeStep === 1 ? (
          <TerminalButton
            position="ll"
            width="100%"
            label="Next"
            onClick={() => handleNext()}
          />
        ) : activeStep === 2 ? (
          <div>
            {' '}
            <PassiveButton
              position="ll"
              width="100%"
              label="Back"
              onClick={() => handleBack()}
            />
            <TerminalButton
              position="ll"
              width="100%"
              label="Next"
              onClick={() => handleNext()}
            />
          </div>
        ) : activeStep === 3 ? (
          <div>
            {' '}
            <PassiveButton
              position="ll"
              width="100%"
              label="Back"
              onClick={() => handleBack()}
            />
            <TerminalButton
              position="ll"
              width="100%"
              label="Next"
              onClick={() => handleNext()}
            />
          </div>
        ) : (
          <div>
            {' '}
            <PassiveButton
              position="ll"
              width="100%"
              label="Back"
              onClick={() => handleBack()}
            />
            <TerminalButton
              position="ll"
              width="100%"
              label="Submit"
              onClick={() => handleSubmit()}
            />
          </div>
        )}
      </footer>
    </div>
  );
};

export default IndividualRegister;
