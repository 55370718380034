const DollarIconSvg = ({ color = '#363352', width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6297 0.0612602C19.5421 0.103514 19.4254 0.192715 19.3768 0.258441C19.1725 0.507263 19.1676 0.638717 19.3427 1.82649C19.4351 2.48376 19.4838 2.92507 19.4497 2.91098C19.4254 2.8922 19.3135 2.81239 19.2065 2.72789C18.3408 2.06123 16.9547 1.36641 15.7144 0.97674C10.9092 -0.525585 5.70992 0.990824 2.56314 4.80767C1.31318 6.32408 0.505819 8.07522 0.131318 10.0799C0.0145909 10.7043 0 10.8686 0 12.0047C0 13.1409 0.0145909 13.3052 0.131318 13.9296C0.282091 14.7418 0.564183 15.7183 0.705228 15.9061C0.924092 16.2019 1.41046 16.2911 1.74118 16.0939C1.92114 15.9859 2.11082 15.6667 2.11082 15.4695C2.11082 15.4084 2.07191 15.2394 2.01841 15.0986C1.59041 13.8826 1.41532 12.0799 1.60014 10.7137C2.05246 7.39448 4.20219 4.46964 7.29547 2.97671C8.89561 2.20677 10.5444 1.86405 12.3731 1.92508C13.91 1.97672 15.301 2.31475 16.6142 2.94854C17.3438 3.30065 18.5499 4.05651 18.5499 4.1551C18.5499 4.17387 18.0295 4.26307 17.3973 4.34758C16.7601 4.43209 16.1813 4.53537 16.1084 4.56823C16.0354 4.60579 15.9236 4.70438 15.8555 4.78889C15.5199 5.21142 15.7339 5.80296 16.2786 5.95319C16.4586 6.00483 20.4905 5.47432 20.8261 5.35695C21.0547 5.27245 21.259 5.05649 21.3028 4.83584C21.3174 4.75133 21.2006 3.77951 21.0255 2.59174C20.6997 0.352336 20.6948 0.333557 20.3884 0.112904C20.2133 -0.013855 19.8339 -0.0373287 19.6297 0.0612602Z"
        fill={color}
      />
      <path
        d="M11.6137 4.81258C11.4921 4.86891 11.3705 4.97689 11.2976 5.08487C11.1906 5.25388 11.1808 5.31022 11.1906 5.81256L11.2003 6.35715L10.9522 6.41349C10.5972 6.48861 9.96492 6.79377 9.60988 7.05198C9.07001 7.44634 8.6031 8.10361 8.40369 8.74209C8.2821 9.12706 8.2821 9.95334 8.40369 10.3336C8.68092 11.2209 9.30347 11.9345 10.1838 12.3758C10.6847 12.6294 11.176 12.7514 11.8909 12.8031C12.601 12.85 12.922 12.9439 13.3208 13.2021C14.3957 13.911 14.3568 15.296 13.243 15.9767C12.8588 16.2068 12.5378 16.2913 11.9833 16.296C11.2587 16.3007 10.9085 16.1598 10.1935 15.5542C9.7412 15.1645 9.35697 15.1411 9.01165 15.4744C8.81224 15.6669 8.74415 15.8875 8.79765 16.1645C8.88033 16.6105 10.1595 17.5213 10.9425 17.6903L11.1954 17.7466L11.2295 18.1973C11.2684 18.8077 11.283 18.8499 11.4921 19.0518C11.7985 19.3476 12.2508 19.3476 12.5621 19.0424C12.7615 18.8499 12.8247 18.5917 12.7858 18.1316L12.7567 17.756L13.0777 17.6668C14.6778 17.2255 15.7673 15.8359 15.6603 14.3758C15.5776 13.2444 14.9015 12.2866 13.778 11.7233C13.2819 11.4744 12.7907 11.3524 12.0757 11.3054C11.3754 11.2538 10.9668 11.1317 10.5826 10.8594C10.1595 10.5637 9.84333 9.99559 9.84333 9.5402C9.84333 9.08481 10.1595 8.51674 10.5826 8.22097C11.0982 7.85009 11.8374 7.70455 12.4454 7.85009C12.9998 7.98154 13.5592 8.26323 13.8802 8.57778C14.0844 8.77496 14.2206 8.86885 14.3714 8.90641C14.8675 9.03786 15.3392 8.67637 15.3392 8.16933C15.3392 7.58249 14.2109 6.75151 13.0047 6.44635L12.7858 6.39002L12.7567 6.10833C12.7372 5.9581 12.7226 5.70458 12.7177 5.54496C12.7129 5.23511 12.6059 5.02854 12.3676 4.85483C12.1779 4.72338 11.8569 4.7046 11.6137 4.81258Z"
        fill={color}
      />
      <path
        d="M22.4117 7.83124C22.042 7.93452 21.7891 8.37583 21.8961 8.73263C22.3095 10.1129 22.4409 10.9298 22.4409 12.0424C22.436 15.2302 20.8991 18.1644 18.2095 20.1174C15.0335 22.4273 10.5687 22.7371 7.02312 20.8921C6.40057 20.5681 5.35975 19.878 5.41811 19.8311C5.4327 19.8217 5.95798 19.7465 6.58539 19.662C7.2128 19.5775 7.78671 19.4789 7.85966 19.4414C7.93262 19.4038 8.04448 19.3052 8.11257 19.2207C8.44816 18.7982 8.23416 18.2067 7.68944 18.0564C7.50948 18.0048 3.47752 18.5353 3.14193 18.6527C2.91334 18.7372 2.70906 18.9531 2.66529 19.1738C2.6507 19.2583 2.76743 20.2207 2.94252 21.4179C3.26838 23.6667 3.26838 23.6761 3.58452 23.9014C3.68666 23.9765 3.79366 24 4.00279 24C4.35298 24 4.59129 23.8451 4.71289 23.5399C4.7907 23.3474 4.78584 23.2958 4.6302 22.2066C4.53293 21.5352 4.48429 21.0846 4.51834 21.0986C4.54266 21.1174 4.65452 21.1972 4.76152 21.2817C5.34516 21.7324 6.50271 22.3756 7.32466 22.7042C12.2029 24.6479 17.8107 23.3474 21.1617 19.493C23.5206 16.7795 24.469 13.2537 23.7784 9.75609C23.6616 9.16924 23.3795 8.25846 23.2628 8.10353C23.0877 7.8641 22.7084 7.74203 22.4117 7.83124Z"
        fill={color}
      />
    </svg>
  );
};

export default DollarIconSvg;
