import IndividualRegister from '@/Components/Pages/Register/IndividualRegister';
import styles from '@/styles/page/LoginRegister/loginRegisterMain.module.scss';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';

import MainContainer from '@/Components/Containers/MainContainer';
import { MobileContext } from '@/utils/DetectMobileContext';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
const Register = () => {
  const router = useRouter();
  const [activeStep, setActiveStep] = useState(1);
  const { isMobile } = useContext(MobileContext);
  const [accountType, setAccountType] = useState('Individual');
  const [selected, setselected] = useState(false);
  return (
    <MainContainer>
      <main
        className={styles.registerMainContainer_main}
        style={{
          justifyContent: isMobile ? '' : 'center',
          minHeight: '50vh',
          paddingBottom: '12px',
        }}
      >
        {!selected && (
          <div
            className={styles.registerMainContainer_main_options}
            style={{
              display: 'flex',
              flexDirection: 'column',

              justifyContent: 'center',
            }}
          >
            <h2>Who are you? (Select your role)</h2>

            <div className={styles.MainContainer_subContainer_box_left}>
              <FormControlLabel
                className={styles.MainContainer_subContainer_box_checkbox}
                label={
                  <span
                    style={{
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '17px',
                    }}
                  >
                    Individual Broker
                  </span>
                }
                control={
                  <Checkbox
                    checked={accountType === 'Individual'}
                    onChange={(e) =>
                      setAccountType(e.target.checked ? 'Individual' : '')
                    }
                  />
                }
              />
              <FormControlLabel
                className={styles.MainContainer_subContainer_box_checkbox}
                label={
                  <span
                    style={{
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '17px',
                    }}
                  >
                    Real Estate Company
                  </span>
                }
                control={
                  <Checkbox
                    checked={accountType === 'Company'}
                    onChange={(e) =>
                      setAccountType(e.target.checked ? 'Company' : '')
                    }
                  />
                }
              />

              <FormControlLabel
                className={styles.MainContainer_subContainer_box_checkbox}
                label={
                  <span
                    style={{
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '17px',
                    }}
                  >
                    Investor
                  </span>
                }
                control={
                  <Checkbox
                    checked={accountType === 'Investor'}
                    onChange={(e) =>
                      setAccountType(e.target.checked ? 'Investor' : '')
                    }
                  />
                }
              />
              <FormControlLabel
                className={styles.MainContainer_subContainer_box_checkbox}
                label={
                  <span
                    style={{
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '17px',
                    }}
                  >
                    Developer
                  </span>
                }
                control={
                  <Checkbox
                    checked={accountType === 'Developer'}
                    onChange={(e) =>
                      setAccountType(e.target.checked ? 'Developer' : '')
                    }
                  />
                }
              />
            </div>
            <Button variant="contained" onClick={() => setselected(true)}>
              Next
            </Button>
          </div>
        )}

        {selected && (
          <IndividualRegister
            accountType={accountType}
            setAccountType={setAccountType}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        )}
      </main>
    </MainContainer>
  );
};

export default Register;
