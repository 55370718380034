import styles from '@/styles/Main/Layout/MainHeader.module.scss';

import terminalLogo from '../../../public/assets/logos/bt_logo.svg';

import { Box, Divider, List, ListItem } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import { MobileContext } from '@/utils/DetectMobileContext';
type Anchor = 'top' | 'left' | 'bottom' | 'right';
const NewLandingHeader = () => {
  const router = useRouter();

  const { isMobile } = useContext(MobileContext);

  const menuOptions = [
    { title: 'Home', link: '/' },
    { title: 'Market Insights', link: '/dubai/property-market-overview' },

    { title: 'Blog', link: '/news' },

    {
      title: 'Company Listings',
      link: '/',
    },
  ];

  const [value, setValue] = useState('/');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [state, setState] = useState({
    left: false,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
  const [fixedButtons, setfixedButtons] = useState();
  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <div className={styles.mainHeaderContainer_logo}>
            <Image
              src={terminalLogo}
              alt="BrokerTerminal"
              onClick={() => router.push('/')}
            />
          </div>
        </ListItem>
        <Divider />
        {/* {menuOptions.map(
          (text, index) =>
            text.title && (
              <ListItem key={text.title + String(index)} disablePadding>
                <ListItemButton onClick={() => router.push(text.link)}>
                  
                  <ListItemText primary={text.title} />
                </ListItemButton>
              </ListItem>
            )
        )} */}
      </List>
    </Box>
  );

  useEffect(() => {
    const filteredOptions = menuOptions.filter(
      (mf) => mf.link && router.pathname.includes(mf.link)
    );
    setValue(
      filteredOptions.length > 1
        ? filteredOptions[1].link
        : filteredOptions[0].link
    );
  }, [router.pathname]);
  return (
    <div
      className={styles.mainHeaderContainer}
      id="top"
      style={{ margin: '0' }}
    >
      <div className={styles.mainHeaderContainer_logo}>
        <Image
          src={terminalLogo}
          priority
          alt="BrokerTerminal"
          onClick={() => router.push('/')}
        />
      </div>

      {/* <div className={styles.mainHeaderContainer_desktopMenu}>
        <Box
          sx={{
            // border: 1,
            borderRadius: '50px',
            overflow: 'hidden',
            // borderColor: '#EBEBEB',
            border: 'none',
            height: '68px',
            display: 'flex',
            padding: '0 10px',
            alignItems: 'center',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="brokerterminal-menu"
            TabIndicatorProps={{
              sx: {
                display: 'none',
                transform: 'scale(0.65)',
              },
            }}
          >
            {menuOptions.map(
              (eachMenu, index) =>
                eachMenu.title && (
                  <Tab
                    style={{
                      // padding: '24px',
                      fontSize: '16px',
                      textTransform: 'capitalize',
                      color: '#FFFFFF',
                    }}
                    disableRipple
                    value={eachMenu.link}
                    onClick={() => router.push(eachMenu.link)}
                    key={String(index) + eachMenu.title}
                    label={eachMenu.title}
                  />
                )
            )}
          </Tabs>
        </Box>
      </div>

      <div
        className={styles.mainHeaderContainer_auth}
        style={{ height: '68px' }}
      >
        <Box className={styles.mainHeaderContainer_auth_box}>
          <button
            className={styles.mainHeaderContainer_auth_register}
            style={{
              color: '#fff',
            }}
            onClick={() => router.push('/register')}
          >
            Register
          </button>
          <button
            className={styles.mainHeaderContainer_auth_login}
            onClick={() => router.push('/login')}
          >
            Login
          </button>
        </Box>
      </div> */}
    </div>
  );
};

export default NewLandingHeader;
