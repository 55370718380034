import { Typography } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';

interface interfaceTermiunalSteo {
  count: number;
  label: string;
  activeStep: number;
  setActiveStep: (c: number) => void;
}
export default function TerminalSteppers({
  count = 2,
  label = 'steps',
  activeStep = 1,
  setActiveStep,
}: interfaceTermiunalSteo) {
  return (
    <div style={{ display: 'flex', gap: '12px' }}>
      <MobileStepper
        variant="progress"
        steps={count + 1}
        position="static"
        activeStep={activeStep}
        sx={{ maxWidth: 30, flexGrow: 1 }}
        nextButton={
          <></> // Disable the next button for now
        }
        backButton={
          <></> // Disable the back button for now
        }
      />
      <Typography variant="body2" color="text.secondary">
        {`${activeStep} of ${count} ${label}`}
      </Typography>
    </div>
  );
}
